body{
    margin: 50px 0px 20px 0px;
    background-color: rgb(0,0,0,0.5);
    color:#181818;
    font-family: 'Roboto Mono', monospace;
    background-image: url("https://wallpaperaccess.com/full/1156888.jpg");
}

.nav-bar{
    height: "500px",
}

footer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}
.projectsScroller{
    grid-row: 1/2;
    grid-column: 1/2;
    margin: 10px;
    border: 1px solid black;
    border-radius: 10px;
}
.emailForm{
    grid-row: 1/2;
    grid-column: 2/3;
}

li {
    list-style-type: none;
    display: inline-block;
    margin: auto;
    padding: 0px 10px 0px 10px;
}

.my-intro {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 7fr 1fr;
    background-color: #0A151C;
    color: #818181;
}

.my-intro img{
    height: 100%;
    width: 100%;
    object-fit: none;
}

.greet {
    padding-left: 10px;
}

.links {
    width: 100%;
    background-color:#2D2D2D;
    text-align: center;
    padding-top: 30px;
    grid-row: 2/2;
    grid-column: 1/3;
    font-size: 18px;
}

.links a{
    color: #181818;
    text-decoration: none;
    margin: 0px 10px 0px 10px;
}

@media only screen and (max-width: 806px){
    .battle-test{
        visibility: hidden;
    }  

    .my-intro{
        grid-template-columns: 1fr;
        grid-template-rows: 4fr 1fr;
        color: black
    }

    .my-intro img{
        position: relative;
        height: 500px;
        width: 100%;
        grid-column: 1/2;
        grid-row: 1/2;
        z-index: 1;
    }

    .greet{
        background-color: rgba(255, 255, 255, 0.5);
        grid-column: 1/2;
        grid-row: 1/2;
        z-index: 2;
    }
    
    .links {
        position: relative;
        width: 100%;
        text-align: center;
        padding-top: 10px;
    }
    
    .links a{
        display: block;
        color: #181818;
        text-decoration: none;
        margin: 10px 0px 10px 0px;
    }
    nav{
        text-align: center;
    }
    li{
        padding: 0px 12% 0px 0px;
    }

    footer{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 2fr;
    }
    .projectsScroller{
        grid-row: 2/3;
        grid-column: 1/2;
        margin: 10px;
        border: 1px solid black;
        border-radius: 10px;
    }
    .emailForm{
        grid-row: 1/2;
        grid-column: 1/2;
        padding-left: 10px;
    }
}

@media only screen and (min-width: 1440px){
    .my-intro img{
        height: 600px;
    }
}